@font-face {
  font-family: Open Sans;
  src: url("../../../resources/fonts/OpenSans/OpenSans-Regular.woff2") format("woff2");
}
.article-row-container[data-v-515f6c] {
  padding: 10px 0;
  width: 100%;
  line-height: 1.5rem;
}
@media only screen and (max-width: 767px) {
.article-row-container[data-v-515f6c] {
    background: rgba(237, 237, 238, 0.7);
    margin: 0 0 15px 0;
    padding: 20px;
}
}
.article-row-container > div[data-v-515f6c] {
  display: inline-block;
  font-family: Open Sans;
  color: #3c4348;
  font-size: 16px;
  vertical-align: top;
}
@media only screen and (max-width: 767px) {
.article-row-container > div.date[data-v-515f6c], .article-row-container > div.council[data-v-515f6c] {
    margin: 0 0 10px 0;
    width: 100%;
    color: #757f87;
}
}
.article-row-container .date[data-v-515f6c] {
  width: 15%;
  text-align: center;
}
@media only screen and (max-width: 767px) {
.article-row-container .date[data-v-515f6c] {
    text-align: left;
}
}
.article-row-container .council[data-v-515f6c] {
  width: 25%;
}
.article-row-container .report[data-v-515f6c] {
  width: 60%;
}
@media only screen and (max-width: 767px) {
.article-row-container .report[data-v-515f6c] {
    color: #3c4248;
    font-size: 20px;
    margin: 0 0 10px 0;
    width: 100%;
}
}
.article-row-container .council[data-v-515f6c],
.article-row-container .report[data-v-515f6c] {
  text-align: left;
}
.article-row-container a[data-v-515f6c] {
  color: #3c4348;
  text-decoration: none;
}
.article-row-container a[data-v-515f6c]:hover {
  text-decoration: underline;
}