@font-face {
  font-family: Open Sans Semi Bold;
  src: url("../../../resources/fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2");
}
@media only screen and (max-width: 767px) {
.article-type-container[data-v-9bc8e7] {
    margin: 0 0 15px 0;
}
}
.article-type-button[data-v-9bc8e7] {
  font-family: Open Sans Semi Bold;
  font-weight: 600;
  text-transform: uppercase;
  color: #3c4348;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #bebebe;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
}
.button-active[data-v-9bc8e7] {
  color: #da4d47;
}