.article-date-container[data-v-d13cd3] {
  color: #757f87;
  margin: 0 0 10px;
  font-family: Open Sans Bold, Arial;
  font-size: 16px;
  font-weight: bold;
}

@font-face {
  font-family: Open Sans Semi Bold;
  src: url("resources/fonts/OpenSans-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: Open Sans;
  src: url("resources/fonts/OpenSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: Open Sans Bold;
  src: url("resources/fonts/OpenSans-Bold.woff2") format("woff2");
}

.article-tile-content[data-v-485faf] {
  color: #757f87;
  background: #ededeeb3;
  height: 100%;
  padding: 25px 35px;
}

.title-link[data-v-485faf] {
  text-decoration: none;
}

.article-title[data-v-485faf] {
  color: #3c4248;
  min-height: 55px;
  margin: 0 0 10px;
  font-family: Open Sans Semi Bold;
  font-size: 20px;
  font-weight: 600;
}

.description[data-v-485faf] {
  font-family: Open Sans;
  font-size: 15px;
  line-height: initial;
}

.article-read-more[data-v-485faf] {
  color: #757f87;
  text-transform: uppercase;
  margin-top: 10px;
  font-family: Open Sans Bold;
  font-weight: 700;
  display: block;
}

.hero-article .article-tile-content[data-v-485faf] {
  background: #c1c6c9b3;
  margin-bottom: 20px;
}

.article-tile-content[data-v-485faf]:hover {
  background: #f08b21;
  transition: all .3s;
}

.article-tile-content:hover .article-title[data-v-485faf], .article-tile-content:hover .description[data-v-485faf] {
  color: #fff;
  transition: all .3s;
}

.article-tile-content:hover .article-read-more[data-v-485faf] {
  color: #3c4248;
  transition: all .3s;
}

.article-tile-content[data-v-485faf]:hover .article-date-container {
  color: #fff;
  transition: all .3s;
}

.pagination {
  text-align: right;
  padding-top: 20px;
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.pagination-item button {
  color: #757f87;
  padding: 0 0 0 8px;
  font-size: 16px;
  text-decoration: none;
}

.pagination-item button:disabled {
  pointer-events: none;
  opacity: .5;
}

.pagination-item button:hover {
  cursor: pointer;
}

.pagination-item button:hover, .pagination-item .active {
  color: #da4d47;
  font-weight: 700;
  text-decoration: underline;
}

.pagination-item .active:disabled {
  opacity: 1;
}

.articles-container[data-v-7ac567] {
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
  display: flex;
}

.articles-container[data-v-7ac567] .article-tile-container {
  flex: 0 0 100%;
  margin: 0 0 20px;
}

@media screen and (width >= 768px) {
  .articles-container[data-v-7ac567] .article-tile-container {
    flex: 0 0 calc(50% - 10px);
  }
}

.articles-container .hero-article[data-v-7ac567] {
  flex: 0 0 100%;
}

.articles-container[data-v-0e884d] {
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
  display: flex;
}

.articles-container[data-v-0e884d] .article-tile-container {
  flex: 0 0 100%;
  margin: 0 0 20px;
}

@media screen and (width >= 768px) {
  .articles-container[data-v-0e884d] .article-tile-container {
    flex: 0 0 calc(50% - 10px);
  }
}

.featured-page-container[data-v-44a879] {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.primary-articles-container[data-v-44a879], .secondary-articles[data-v-44a879] {
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
  display: flex;
}

.primary-articles-container[data-v-44a879] .article-tile-container, .secondary-articles[data-v-44a879] .article-tile-container {
  flex: 0 0 100%;
  margin: 0 0 20px;
}

@media screen and (width >= 768px) {
  .primary-articles-container[data-v-44a879] .article-tile-container, .secondary-articles[data-v-44a879] .article-tile-container {
    flex: 0 0 calc(50% - 10px);
  }
}

.primary-articles-container[data-v-44a879] .hero-article, .secondary-articles[data-v-44a879] .hero-article {
  flex: 0 0 100%;
}

.secondary-title > h2[data-v-44a879] {
  color: #da4d47;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 25px;
  font-family: Ropa Sans;
  font-size: 28px;
  font-weight: 400;
}

.secondary-articles-container[data-v-44a879] {
  padding-bottom: 40px;
}

.secondary-articles-container .secondary-articles[data-v-44a879] .article-title, .secondary-articles-container .secondary-articles[data-v-44a879] .description, .secondary-articles-container .secondary-articles[data-v-44a879] .article-read-more, .secondary-articles-container .secondary-articles[data-v-44a879] .article-date-container {
  color: #fff;
}

.secondary-articles-container .secondary-articles[data-v-44a879] .article-tile-content {
  background: #768188;
  min-height: 180px;
  padding: 30px 20px;
}

.secondary-articles-container .secondary-articles[data-v-44a879] .article-title {
  min-height: 30px;
}

.secondary-articles-container .secondary-articles[data-v-44a879] .article-tile-content:hover {
  background: #da4d46;
  transition: all .3s;
}

.secondary-articles-container .secondary-articles[data-v-44a879] .article-tile-content:hover .article-read-more {
  color: #3c4248;
  transition: all .3s;
}

.articles-container[data-v-d2b34b] {
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
  display: flex;
}

.articles-container[data-v-d2b34b] .article-tile-container {
  flex: 0 0 100%;
  margin: 0 0 20px;
}

@media screen and (width >= 768px) {
  .articles-container[data-v-d2b34b] .article-tile-container {
    flex: 0 0 calc(50% - 10px);
  }
}

@font-face {
  font-family: Open Sans Semi Bold;
  src: url("resources/fonts/OpenSans-SemiBold.woff2") format("woff2");
}

@media only screen and (width <= 767px) {
  .article-type-container[data-v-9bc8e7] {
    margin: 0 0 15px;
  }
}

.article-type-button[data-v-9bc8e7] {
  text-transform: uppercase;
  color: #3c4348;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #bebebe;
  border-radius: 5px;
  margin-right: 20px;
  padding: 10px 20px;
  font-family: Open Sans Semi Bold;
  font-size: 16px;
  font-weight: 600;
}

.button-active[data-v-9bc8e7] {
  color: #da4d47;
}

@font-face {
  font-family: Open Sans Semi Bold;
  src: url("resources/fonts/OpenSans-SemiBold.woff2") format("woff2");
}

.article-selector-button[data-v-81b15b] {
  color: #3c4348;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 5px;
  margin: 0 30px;
  padding: 10px;
  font-family: Open Sans Semi Bold;
  font-size: 16px;
  font-weight: 600;
}

.article-selector-button[data-v-81b15b]:first-child {
  margin-left: 0;
  padding-left: 0;
}

@media only screen and (width <= 767px) {
  .article-selector-button[data-v-81b15b] {
    margin: 0 30px 10px 0;
    padding: 0 10px;
  }
}

.button-active[data-v-81b15b] {
  color: #da4d47;
}

@font-face {
  font-family: Open Sans;
  src: url("resources/fonts/OpenSans-Regular.woff2") format("woff2");
}

.article-row-container[data-v-515f6c] {
  width: 100%;
  padding: 10px 0;
  line-height: 1.5rem;
}

@media only screen and (width <= 767px) {
  .article-row-container[data-v-515f6c] {
    background: #ededeeb3;
    margin: 0 0 15px;
    padding: 20px;
  }
}

.article-row-container > div[data-v-515f6c] {
  color: #3c4348;
  vertical-align: top;
  font-family: Open Sans;
  font-size: 16px;
  display: inline-block;
}

@media only screen and (width <= 767px) {
  .article-row-container > div.date[data-v-515f6c], .article-row-container > div.council[data-v-515f6c] {
    color: #757f87;
    width: 100%;
    margin: 0 0 10px;
  }
}

.article-row-container .date[data-v-515f6c] {
  text-align: center;
  width: 15%;
}

@media only screen and (width <= 767px) {
  .article-row-container .date[data-v-515f6c] {
    text-align: left;
  }
}

.article-row-container .council[data-v-515f6c] {
  width: 25%;
}

.article-row-container .report[data-v-515f6c] {
  width: 60%;
}

@media only screen and (width <= 767px) {
  .article-row-container .report[data-v-515f6c] {
    color: #3c4248;
    width: 100%;
    margin: 0 0 10px;
    font-size: 20px;
  }
}

.article-row-container .council[data-v-515f6c], .article-row-container .report[data-v-515f6c] {
  text-align: left;
}

.article-row-container a[data-v-515f6c] {
  color: #3c4348;
  text-decoration: none;
}

.article-row-container a[data-v-515f6c]:hover {
  text-decoration: underline;
}

@font-face {
  font-family: Ropa Sans;
  src: url("resources/fonts/RopaSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: Ropa Sans Bold;
  src: url("resources/fonts/RopaSans-Regular.woff2") format("woff2");
}

@media (width >= 767px) and (width <= 1200px) {
  .reports-page-conatiner[data-v-1db2f2] {
    padding-inline: 20px;
  }
}

.category-selectors[data-v-1db2f2], .article-types[data-v-1db2f2], .council-selectors[data-v-1db2f2] {
  flex-wrap: wrap;
  padding: 0 0 20px;
  display: flex;
}

.category-selectors[data-v-1db2f2] {
  padding-bottom: 0;
}

.all-reports-info[data-v-1db2f2] {
  color: #3c4348;
  padding: 20px 0 15px;
  font-family: Ropa Sans;
  font-size: 19px;
  font-weight: 600;
}

.all-reports-container[data-v-1db2f2] {
  border-collapse: collapse;
  width: 100%;
  display: table;
}

.reports-title[data-v-1db2f2] {
  color: #3c4348;
  text-transform: uppercase;
  border-top: 2px solid #d6d8d9;
  border-bottom: 2px solid #d6d8d9;
  padding: 10px 0;
  font-family: Ropa Sans Bold;
  font-size: 23px;
  font-weight: 700;
}

@media only screen and (width <= 767px) {
  .reports-title[data-v-1db2f2] {
    display: none;
  }
}

.reports-title > div[data-v-1db2f2] {
  display: inline-block;
}

.reports-title .date[data-v-1db2f2] {
  text-align: center;
  width: 15%;
}

.reports-title .council[data-v-1db2f2] {
  width: 25%;
}

.reports-title .report[data-v-1db2f2] {
  width: 60%;
}

.reports-title .council[data-v-1db2f2], .reports-title .report[data-v-1db2f2] {
  text-align: left;
}

.articles-container[data-v-464cdf] {
  color: #757f87;
}

.article-tile-container[data-v-464cdf] {
  margin-block: 1rem;
}

.no-articles[data-v-464cdf] {
  text-align: center;
  margin-block: 1rem;
}

.search-container[data-v-464cdf] {
  justify-content: center;
  margin-bottom: 3rem;
  display: flex;
}

.search-container form[data-v-464cdf] {
  color: #757f87;
  border: 1px solid #757f87;
  border-radius: .7rem;
  flex-direction: row-reverse;
  align-items: center;
  width: 50%;
  height: 3rem;
  display: flex;
}

@media only screen and (width <= 767px) {
  .search-container form[data-v-464cdf] {
    width: 90%;
  }
}

.search-container button[data-v-464cdf] {
  stroke: none;
  fill: #da4d47;
  cursor: pointer;
  align-items: center;
  padding-inline: .6rem;
  display: flex;
}

.search-container #search-term[data-v-464cdf] {
  border: none;
  border-radius: .7rem;
  flex-grow: 1;
  height: calc(100% - 2px);
}

.search-container #search-term[data-v-464cdf]:focus {
  outline: none;
}

/*# sourceMappingURL=search.css.map */
