@font-face {
  font-family: Ropa Sans;
  src: url("../../resources/fonts/RopaSans/RopaSans-Regular.woff2") format("woff2");
}
@font-face {
  font-family: Ropa Sans Bold;
  src: url("../../resources/fonts/RopaSans/RopaSans-Regular.woff2") format("woff2");
}
@media (min-width: 767px) and (max-width: 1200px) {
.reports-page-conatiner[data-v-1db2f2] {
    padding-inline: 20px;
}
}
.category-selectors[data-v-1db2f2],
.article-types[data-v-1db2f2],
.council-selectors[data-v-1db2f2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0 0 20px 0;
}
.category-selectors[data-v-1db2f2] {
  padding-bottom: 0;
}
.all-reports-info[data-v-1db2f2] {
  color: #3c4348;
  font-family: Ropa Sans;
  font-size: 19px;
  font-weight: 600;
  padding: 20px 0 15px 0;
}
.all-reports-container[data-v-1db2f2] {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.reports-title[data-v-1db2f2] {
  border-bottom: 2px solid #d6d8d9;
  border-top: 2px solid #d6d8d9;
  color: #3c4348;
  font-family: Ropa Sans Bold;
  font-size: 23px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 0;
}
@media only screen and (max-width: 767px) {
.reports-title[data-v-1db2f2] {
    display: none;
}
}
.reports-title > div[data-v-1db2f2] {
  display: inline-block;
}
.reports-title .date[data-v-1db2f2] {
  text-align: center;
  width: 15%;
}
.reports-title .council[data-v-1db2f2] {
  width: 25%;
}
.reports-title .report[data-v-1db2f2] {
  width: 60%;
}
.reports-title .council[data-v-1db2f2],
.reports-title .report[data-v-1db2f2] {
  text-align: left;
}