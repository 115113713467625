.articles-container[data-v-0e884d] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 40px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.articles-container[data-v-0e884d] .article-tile-container {
  margin: 0 0 20px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}
@media screen and (min-width: 768px) {
.articles-container[data-v-0e884d] .article-tile-container {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 10px);
            flex: 0 0 calc(50% - 10px);
}
}