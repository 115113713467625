
.pagination {
  list-style-type: none;
  text-align: right;
  padding-top: 20px;
}
.pagination-item {
  display: inline-block;
button {  
    color: #757f87;
    font-size: 16px;
    text-decoration: none;
    padding: 0 0 0 8px;
&:disabled {
      pointer-events: none;
      opacity: .5;
}
&:hover {
      cursor: pointer;
}
}
button:hover, .active {
    color: #da4d47;
    font-weight: 700;
    text-decoration: underline;
}
.active:disabled {
    opacity: 1;
}
}
