@font-face {
  font-family: Open Sans Semi Bold;
  src: url("../../../resources/fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: Open Sans;
  src: url("../../../resources/fonts/OpenSans/OpenSans-Regular.woff2") format("woff2");
}
@font-face {
  font-family: Open Sans Bold;
  src: url("../../../resources/fonts/OpenSans/OpenSans-Bold.woff2") format("woff2");
}
.article-tile-content[data-v-485faf] {
  background: rgba(237, 237, 238, 0.7);
  color: #757f87;
  padding: 25px 35px;
  height: 100%;
}
.title-link[data-v-485faf] {
  text-decoration: none;
}
.article-title[data-v-485faf] {
  min-height: 55px;
  color: #3c4248;
  font-size: 20px;
  font-family: Open Sans Semi Bold;
  font-weight: 600;
  margin: 0 0 10px 0;
}
.description[data-v-485faf] {
  font-size: 15px;
  font-family: Open Sans;
  line-height: initial;
}
.article-read-more[data-v-485faf] {
  color: #757f87;
  display: block;
  font-weight: 700;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: Open Sans Bold;
}
.hero-article .article-tile-content[data-v-485faf] {
  background: rgba(193, 198, 201, 0.7);
  margin-bottom: 20px;
}
.article-tile-content[data-v-485faf]:hover {
  background: #f08b21;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.article-tile-content:hover .article-title[data-v-485faf],
.article-tile-content:hover .description[data-v-485faf] {
  color: white;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.article-tile-content:hover .article-read-more[data-v-485faf] {
  color: #3c4248;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.article-tile-content[data-v-485faf]:hover .article-date-container {
  color: white;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}