.featured-page-container[data-v-44a879] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.primary-articles-container[data-v-44a879], .secondary-articles[data-v-44a879] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 40px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.primary-articles-container[data-v-44a879] .article-tile-container, .secondary-articles[data-v-44a879] .article-tile-container {
  margin: 0 0 20px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}
@media screen and (min-width: 768px) {
.primary-articles-container[data-v-44a879] .article-tile-container, .secondary-articles[data-v-44a879] .article-tile-container {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 10px);
            flex: 0 0 calc(50% - 10px);
}
}
.primary-articles-container[data-v-44a879] .hero-article, .secondary-articles[data-v-44a879] .hero-article {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}
.secondary-title > h2[data-v-44a879] {
  margin-bottom: 25px;
  color: #da4d47;
  font-family: Ropa Sans;
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}
.secondary-articles-container[data-v-44a879] {
  padding-bottom: 40px;
}
.secondary-articles-container .secondary-articles[data-v-44a879] .article-title,
.secondary-articles-container .secondary-articles[data-v-44a879] .description,
.secondary-articles-container .secondary-articles[data-v-44a879] .article-read-more,
.secondary-articles-container .secondary-articles[data-v-44a879] .article-date-container {
  color: white;
}
.secondary-articles-container .secondary-articles[data-v-44a879] .article-tile-content {
  min-height: 180px;
  padding: 30px 20px 30px 20px;
  background: #768188;
}
.secondary-articles-container .secondary-articles[data-v-44a879] .article-title {
  min-height: 30px;
}
.secondary-articles-container .secondary-articles[data-v-44a879] .article-tile-content:hover {
  background: #da4d46;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.secondary-articles-container .secondary-articles[data-v-44a879] .article-tile-content:hover .article-read-more {
  color: #3c4248;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}