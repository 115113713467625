@font-face {
  font-family: Open Sans Semi Bold;
  src: url("../../../resources/fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2");
}
.article-selector-button[data-v-81b15b] {
  font-family: Open Sans Semi Bold;
  font-weight: 600;
  font-size: 16px;
  color: #3c4348;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 10px;
  margin: 0 30px;
  text-transform: uppercase;
  cursor: pointer;
}
.article-selector-button[data-v-81b15b]:first-child {
  margin-left: 0;
  padding-left: 0;
}
@media only screen and (max-width: 767px) {
.article-selector-button[data-v-81b15b] {
    margin: 0 30px 10px 0;
    padding: 0 10px;
}
}
.button-active[data-v-81b15b] {
  color: #da4d47;
}