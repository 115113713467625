.articles-container[data-v-464cdf] {
  color: #757f87;
}
.article-tile-container[data-v-464cdf] {
  margin-block: 1rem;
}
.no-articles[data-v-464cdf] {
  text-align: center;
  margin-block: 1rem;
}
.search-container[data-v-464cdf] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 3rem;
}
.search-container form[data-v-464cdf] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 3rem;
  border: 1px solid #757f87;
  border-radius: 0.7rem;
  color: #757f87;
  width: 50%;
}
@media only screen and (max-width: 767px) {
.search-container form[data-v-464cdf] {
    width: 90%;
}
}
.search-container button[data-v-464cdf] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  stroke: none;
  fill: #da4d47;
  padding-inline: 0.6rem;
  cursor: pointer;
}
.search-container #search-term[data-v-464cdf] {
  border: none;
  border-radius: 0.7rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: calc(100% - 2px);
}
.search-container #search-term[data-v-464cdf]:focus {
  outline: none;
}